// Custom Theming for Angular Material
@use '@angular/material' as mat;

// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'colors';



@include mat.core();

/* For use in src/lib/core/theming/_palette.scss */
$md-mcgpalette0: (
  50 : #e2e8ed,
  100 : #b7c6d2,
  200 : #87a0b4,
  300 : #577a96,
  400 : #335e80,
  500 : #0f4169,
  600 : #0d3b61,
  700 : #0b3256,
  800 : #082a4c,
  900 : #041c3b,
  A100 : #71a6ff,
  A200 : #3e86ff,
  A400 : #0b66ff,
  A700 : #0059f1,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$matelex-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$matelex-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);
$my-typography: mat.m2-define-typography-config( $font-family: Montserrat );
// The warn palette is optional (defaults to red).
$matelex-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$matelex-theme: mat.m2-define-light-theme(
  (
    color: (
      primary:  mat.m2-define-palette($md-mcgpalette0,500),
      accent: $matelex-accent,
      warn: $matelex-warn
    ),
  )
);
$matelex-theme: mat.m2-define-light-theme(
    (
      color: (
        primary:  mat.m2-define-palette($md-mcgpalette0,500),
        accent: $matelex-accent,
        warn: $matelex-warn
      )
    )
);


@include mat.all-component-themes($matelex-theme);




html,
body {
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// Apply 'Ubuntu' font to all elements except specified ones
*:not(table):not(.mat-mdc-table *):not(.mdc-tab__text-label *):not(.mdc-list-item__primary-text *):not(.mdc-list-item__content *):not(mat-nav-list *):not(.mat-mdc-nav-list *),
*:not(table):not(.mat-mdc-nav-list):not(mat-nav-list *) {
  font-family: 'Ubuntu', sans-serif;
}

// Specifically exclude <mat-nav-list> and its children from 'Montserrat' font
mat-nav-list,
.mat-mdc-nav-list,
mat-nav-list *,
.mat-mdc-nav-list * {
  font-family: inherit; // Or set another font-family if needed
}

.material-icons {
  font-family: 'Material Icons' !important;
}
.material-icons-outlined {
  font-family: 'Material Icons Outlined' !important;

}

.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}
body {
  background-color: $general-light-blue-bg;
  margin: 0;
  font-family: 'Ubuntu', sans-serif, Roboto, 'Helvetica Neue', sans-serif !important;
  @media (max-width: 1400px) {
    overflow-x: hidden;
  }
}






 h1 {
  font-size: 24px;
  line-height: 26px;
  letter-spacing: -0.06px;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 600 !important;
}

 .pv-title-1{
   font-size: 24px;
   line-height: 26px;
   letter-spacing: -0.06px;
   font-family: 'Montserrat', sans-serif !important;
   font-weight: 600 !important;
 }

.light-grey {
  color: $light-grey-color;
}
.light-grey-bg {
  color: $light-grey-bg;
}

.separator-custom{
  border-bottom: 1px solid $light-grey-bg;
}
.font-bold {
  font-weight: 700;
}
.font-semibold {
  font-weight: 600;
}

.font-14 {
  font-size: 14px;
}

.font-15 {
  font-size: 15px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px !important;
}

.no-border {
  border: 0 !important;
}

app-dashboard,
app-dni-list,
app-user-list,
app-customer-location-list,
app-dni-details {
  width: 100%;
}

// BUTTON
button {
  &:disabled {
    pointer-events: unset !important;
    cursor: not-allowed !important;
  }
}

.mat-raised-button {
  padding: 0 16px 0 12px;
  &.mat-primary {
    background-color: $indigo-color !important;
    font-size: 14px;
    line-height: 36px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-weight: 500;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.13);
    .mat-icon {
      margin-right: 5px;
      font-size: 20px;
      line-height: 23px;
    }
  }
  &.mat-basic {
    height: 36px;
    background-color: $light-grey-bg;
  }
  &.mat-success {
    background-color: #4caf50 !important;
    color: #fff !important;
  }
}






.btn-secondary {
  background-color: $indigo-color;
  color: #fff;
}

.content-header {
  display: flex;
  justify-content: space-between;
}



.mat-mdc-select-arrow { opacity: 0; }

.cdk-overlay-container .mat-mdc-select-arrow {
  opacity: 0 !important;
}

// Alerts

.alert-message {
  display: inline-block;
  padding: 0 7px;
  background-color: #eceff1;
  color: $primary-grey-color;
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.83px;
  font-family: 'Montserrat', sans-serif !important;
  word-break: keep-all;
  &.danger {
    background-color: #feebee;
    color: $red-color;
  }
  &.warning {
    background-color: #fff3e0;
    color: $orange-color;
  }
  &.success {
    background-color: $light-green-bg;
    color: $green-color;
  }
}
.indicatif .ng-input, .indicatif .ng-value {
  padding: 10px;
}

// TABLE








mat-panel-title {
  .mat-badge-content {
    position: relative;
    top: -1px !important;
    right: auto !important;
    margin-left: 10px;
    width: auto !important;
    padding: 0 3px;
    min-width: 14px;
    height: 15px;
    border-radius: 0;
    background-color: #feebee;
    color: $red-color;
  }
}



.content-wrapper {
  padding: 34px 50px;
  width: 100%;
  @media (max-width: 1439px) {
    padding: 34px 5px 34px 25px;
  }
  &.dni-list-content {

    @media (max-width: 1439px) {
      width: calc(100% - 260px);
    }
    @media (max-width: 904px) {
      // 991
      width: 94%;
    }
  }
}










@media (max-width: 1239px) {
  //1024
  .content-wrapper {
    padding: 34px 15px;
  }
}

@media (max-width: 904px) {
  //991
  main {
    flex-direction: column;
  }
}

.matelex-backgroud {
  position: relative;
  background-color: $general-light-blue-bg;
}
.ng-dropdown-panel {
  margin-top: -16px;
}
.ng-select {
  width: 360px;
  display: inline-block !important;
  margin-right: 16px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  font-weight: unset;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: transparent;
  color: grey;
}


.chart {
  display: block;
  width: 100%;
}

.break-line{
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
}

@import "style/badge";
@import "style/tooltip";
@import "style/tab";
@import "style/_paginator.scss";
@import "style/_checkbox.scss";
@import "style/_list.scss";
@import "style/_spinner.scss";
@import "style/table.scss";
@import 'colors';
@import "style/_card.scss";
@import "style/form-field";
@import "style/button";
@import "style/_expansion-panel.scss";
@import "style/_dialog.scss";
@import "style/_menu.scss";
@import "style/_slide-toggle.scss";



.border-end-only-md{
  border-right: 1px solid $light-grey-bg
}
@media (max-width: 767.98px) {
  .border-end-only-md{
    border-left: 0!important;
    border-right: 0!important;
    border-right: 0!important;
    border-top: 0!important;
    border-width: unset!important;
  } }


.wrapper {
  display: inline-block;

  &:empty {
    display: none;
  }
}

.mat-card-item {
  padding: 17px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  border-bottom: 1px solid $light-grey-bg;
}


.mat-divider{
  border-bottom: 1px solid $light-grey-bg;
}


.no-results-container{
  padding: 25px 0;
}



.filter-section{

  .card-filter-content{
    padding: 0!important;
  }
  .header-expansion-title {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    color:rgba(0, 0, 0, 0.87);
    font-weight: 600;
    line-height: 157%; /* 21.98px */
    letter-spacing: 0.1px;
  }
  .header-expansion-description{
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Ubuntu,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%; /* 20.02px */
    letter-spacing: 0.15px;
    text-transform: lowercase;
  }
  .expansion-content{
    border-bottom: 1px solid $light-grey-bg;
    border-radius: 0!important;
    &:last-of-type {
      border-bottom: none;
    }
  }


  .checkbox-expansion-label{
    font-family: Ubuntu, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 143%; /* 17.16px */
    letter-spacing: 0.15px;
  }

  .header-expansion{
    padding: 10px 30px 10px 10px!important;
  }
}

.datepicker-pos{
  position: absolute;
  right: 10px;
  top: 60px;
  z-index: 10000;
}

@media (max-width: 576px) {
  .datepicker-pos{
    position: unset;
    display: flex;
    z-index: 10000;
  }
}
@media all and (max-width: 767px) {
  [class^="col"] {
    margin-bottom: 20px;
  }
}
@import "@ng-select/ng-select/themes/material.theme.css";

h3 {
  font-size: 16px !important;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 500;
  letter-spacing: 0.15px;
}

ng-select.auto-grow {
  display: flex;

  .ng-select-container {
    min-width: 100px;
    width: auto;
  }

  .ng-dropdown-panel {
    width: auto;
  }
}


html.remove-scroll, body.remove-scroll {
  overflow: hidden;
}

 .material-symbols-outlined {
   font-variation-settings:
     'FILL' 0,
     'wght' 400,
     'GRAD' 0,
     'opsz' 24
 }


/* Target all scrollbars */
* {
  scrollbar-width: thin;
}

/* WebKit (Safari/Chrome) */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}



/* WebKit (Safari/Chrome) */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

*::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}
